<template>
	<!--begin::Content-->
	<div class="flex-grow-1" style="margin-bottom: 26px" v-if="!adminSiteLoading && adminSite.showQuota">
		<!--begin::Card-->
		<div class="card card-custom">
			<!--begin::Header-->
			<div class="card-header py-3">
				<div class="card-title align-items-start flex-column">
					<h3 class="card-label font-weight-bolder text-dark">Monthly Quota Alert</h3>
					<span class="text-muted font-weight-bold font-size-sm mt-1"
						>Note: Quotas are informational only. Traffic will NOT stop when quota is reached.</span
					>
				</div>
				<div class="card-toolbar">
					<button
						type="reset"
						class="btn btn-success mr-2"
						@click="saveQuota()"
						ref="kt_save_changes"
					>
						Save Changes
					</button>
					<button
						type="reset"
						class="btn btn-secondary"
						v-show="adminSite.quotaStatus === 'addingQuota'"
						@click.prevent="cancel"
					>
						Cancel
					</button>
				</div>
			</div>
			<!--end::Header-->
			<!--begin::Form-->
			<b-form class="form">
				<div class="card-body">
					<!--begin::Form Group-->
					<div class="form-group row">
						<label class="col-xl-3 col-lg-3 col-form-label">Quota (in MB)</label>
						<div class="col-lg-9 col-xl-6">
							<div style="display: flex">
								<input
									class="form-control form-control-lg form-control-solid"
									type="number"
									v-model="quota"
								/>
								<div class="input-group-append">
									<span class="input-group-text">MB</span>
								</div>
							</div>
						</div>
					</div>

					<!--begin::Form Group-->
					<div class="form-group row">
						<label class="col-xl-3 col-lg-3 col-form-label">Warning Percentage</label>
						<div class="col-lg-9 col-xl-6">
							<div style="display: flex">
								<input
									class="form-control form-control-lg form-control-solid"
									type="number"
									v-model="warningPercent"
								/>
								<div class="input-group-append">
									<span class="input-group-text">%</span>
								</div>
							</div>
							<span class="text-muted font-weight-bold font-size-md mt-1"
								>(% of quota at which a warning email is sent, email also sent at 100%)</span
							>
						</div>
					</div>

					<!--begin::Form Group-->
					<div class="separator separator-dashed my-5"></div>

					<!--begin::Form Group-->
					<div class="form-group row">
						<label class="col-xl-3 col-lg-3 col-form-label">Email</label>
						<div class="col-lg-9 col-xl-6">
							<div class="form-group">
								<label class="form__label label-heading" for="textarea-default">
									<div>Email to use for quota alerts:</div>
								</label>
								<b-form-input
									id="input-1"
									type="email"
									placeholder="Enter email"
									required
									v-model="email"
								></b-form-input>
							</div>
						</div>
					</div>

					<div class="d-flex justify-content-end">
						<button
							type="reset"
							class="btn btn-danger mr-2"
							v-show="adminSite.quotaStatus === 'added'"
							@click.prevent="deleteQuota"
						>
							Delete
						</button>
					</div>
				</div>
			</b-form>
			<div class="d-flex justify-content-start">
				<b-button class="ml-5 mb-5 btn btn-sm btn-secondary btn-outline" v-b-modal.advquotamodal>
					Advanced Quota Properties
				</b-button>
				<b-alert
					class="ml-5 mb-5"
					variant="danger"
					fade
					:show="advancedSaved"
					@dismissed="advancedSaved = 0"
					@dismiss-count-down="advancedSavedChanged"
					>Saved.</b-alert
				>
				<b-modal size="lg" id="advquotamodal" @ok="ok()">
					<template #modal-title>Edit Advanced Quota Properties - {{ adminSite.name }}</template>
					<div v-show="adminSite.data.devices.length > 0">
						<b>Select additional modems/interfaces to included in quota total:</b>
						<br />
						<span class="small"
							>Default is total of all modems, any selection below overrides that.</span
						>
						<br /><br />
						<div v-for="d in devices1" v-bind:key="d.DeviceId">
							<b-form-checkbox v-model="advancedquotal[d.DeviceId]"> {{ d.Name }}</b-form-checkbox>
						</div>
						<hr />
						<div v-for="d in devices2" v-bind:key="d.DeviceId">
							<b-form-checkbox v-model="advancedquotal[d.DeviceId]"> {{ d.Name }}</b-form-checkbox>
						</div>
					</div>
				</b-modal>
			</div>
			<!--end::Form-->
		</div>
		<!--end::Card-->
	</div>
	<!--end::Content-->
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_SITE_QUOTA, REMOVE_QUOTA } from '@/core/services/store/admin.module';

export default {
	name: 'SiteProfileQuota',
	data() {
		return {
			loaded: false,
			quota: 0,
			warningPercent: 0,
			email: '',
			status: '',
			advancedquotal: {},
			advancedSaved: false,
		};
	},
	components: {
		// ITCSpinner,
	},
	computed: {
		...mapGetters(['adminSite', 'adminSiteLoading']),
		devices1() {
			return this.adminSite.data.devices.filter((d) => d.TypeId == 0);
		},
		devices2() {
			return this.adminSite.data.devices.filter(
				(d) => d.TypeId == 10 && d.Source != '!CPU Load' && d.Source != '!Response Time'
			);
		},
	},
	methods: {
		loadQuotaData() {
			if (typeof this.adminSite.data.quota !== 'undefined') {
				this.quota = this.adminSite.data.quota.quota;
				this.warningPercent = this.adminSite.data.quota.warnpercent;
				this.email = this.adminSite.data.quota.email;
				if (this.adminSite.data.quota.properties) {
					this.advancedquotal = JSON.parse(JSON.stringify(this.adminSite.data.quota.properties));
				}
			}
		},
		saveQuota() {
			let data = {
				SiteId: this.adminSite.id,
				quota: this.quota,
				email: this.email,
				warnpercent: this.warningPercent,
			};
			// set spinner to submit button
			const submitButton = this.$refs['kt_save_changes'];
			submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

			this.$http.post('editsitequota', data).then((res) => {
				data.quotaStatus = 'added';
				this.$store.commit(UPDATE_SITE_QUOTA, data);
				submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
			});
		},
		deleteQuota() {
			this.$http.post('deletesitequota', { id: this.adminSite.id }).then((res) => {
				this.$store.commit(REMOVE_QUOTA, 'deleted');
				this.quota = 0;
				this.warningPercent = 0;
				this.email = '';
			});
		},
		cancel() {
			this.$store.commit(REMOVE_QUOTA);
		},
		// advanced quota
		ok() {
			this.$http
				.post('advancedquota', { SiteId: this.adminSite.id, data: this.advancedquotal })
				.then((res) => {
					if (res.data.data.result == 1) {
						this.advancedSaved = 5;
					}
				});
		},
		advancedSavedChanged(advancedSaved) {
			this.advancedSaved = advancedSaved;
		},
	},
	watch: {
		adminSiteLoading(loading) {
			if (!loading) {
				this.loadQuotaData();
			}
		},
	},
};
</script>

<style scoped></style>
